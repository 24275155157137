<template>
    <div class="park-notice-dialog">
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-position="top"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item label="公告标题" prop="noticeTitle">
              <el-input
                maxlength="50"
                placeholder="请输入公告标题"
                :disabled="currentState === 'view'"
                v-model="ruleForm.noticeTitle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发布时间" prop="pubdate">
              <el-date-picker
                :disabled="currentState === 'view'"
                style="width: 100%"
                v-model="ruleForm.pubdate"
                type="datetime"
                value-format="timestamp"
                :picker-options="pickerOptions"
                placeholder="请选择发布时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="展示位置" prop="showAddress">
              <el-select
                :disabled="currentState === 'view'"
                placeholder="请选择展示位置"
                style="width: 100%"
                v-model="ruleForm.showAddress"
              >
                <el-option
                  v-for="(item, index) in showAddressList"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发布人" prop="publisher">
              <el-input :disabled="true" v-model="ruleForm.publisher"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公告类型" prop="noticeType">
              <el-select
                :disabled="currentState === 'view'"
                style="width: 100%"
                placeholder="请选择公告类型"
                v-model="ruleForm.noticeType"
              >
                <el-option
                  v-for="(item, index) in noticeTypeList"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 暂时注释封面图片 -->
        <!-- <el-row>
          <el-form-item label="封面图片" prop="photoIdListId">
            <div class="upload-container" v-loading="uploadLoading">
              <el-upload
                :disabled="currentState === 'view'"
                class="avatar-uploader"
                :action="uploadPath"
                :with-credentials="true"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="tips-container">
                <div>
                  <div>
                    温馨提示：最多上传1张，图片大小不能超过2m，图片格式为jpg，png。
                  </div>
                  <div>图片尺寸212*112</div>
                </div>
                <div
                  v-show="
                    currentState !== 'view' && ruleForm.photoIdListId.length > 0
                  "
                  class="delete-icon"
                  @click="deleteHandler"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-row> -->
        <el-row>
          <el-form-item label="链接地址" prop="linkAddress">
            <el-input
              :disabled="currentState === 'view'"
              v-model="ruleForm.linkAddress"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-form-item
          align="right"
          v-if="currentState === 'edit' || currentState === 'add'"
        >
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <el-button @click="resetForm()">取消</el-button>
        </el-form-item>
        <el-form-item align="right" v-if="currentState === 'view'">
          <el-button @click="changeStateToEdit" type="primary">编辑</el-button>
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
  import { envInfo } from "@/constants/envInfo";
  import { findProfile } from "@/api/ruge/sso/personal/personal.js";
  import {
    addParkNotice,
    getNoticeDetailById,
    updateParkNotice,
  } from "@/api/ruge/jwPark/parkNotice";
  import { checkUrl } from "@/utils/utils.js";
  export default {
    name: "parkNoticeDialog",
    props: {
      showAddressList: {
        type: Array,
        required: true,
      },
      noticeTypeList: {
        type: Array,
        required: true,
      },
      dialogStatus: {
        type: String,
        required: true,
      },
      id: {
        required: false,
      },
    },
    watch: {
      dialogStatus: {
        handler(val) {
          this.currentState = val;
        },
        immediate: true,
      },
      id: {
        handler(id) {
          if (!id) return;
          this.getNoticeDetail(id);
        },
        immediate: true,
      },
    },
    created() {
      if (this.dialogStatus === "add") {
        findProfile({ userAccount: this.$store.getters.userAccount }).then(
          (r) => {
            this.ruleForm.publisher = r.userName;
          }
        );
      }
    },
    data() {
      const validatorPubdate = (rule, value, callback) => {
        if (!value) {
          callback(new Error("发布时间为必填"));
          return;
        }
        if (
          value <= Date.now() - 1000 * 60 * 30 ||
          value > Date.now() + 3 * 31 * 24 * 60 * 60 * 1000
        ) {
          callback(new Error("发布时间必须大于当前时间，且在三个月以内"));
        } else {
          callback();
        }
      };
      const validatorPhoto = (rule, value, callback) => {
        if (this.ruleForm.photoIdListId.length === 0) {
          callback(new Error("封面图片为必填"));
        } else {
          callback();
        }
      };
      const validatorLinkAddress = (rule, value, callback) => {
        checkUrl(value)
          .then((res) => {
            if (res) {
              callback();
            } else {
              callback(new Error("链接地址无效"));
            }
          })
          .catch((errorCode) => {
            if (errorCode === 1) {
              callback(new Error("链接地址为必填"));
            } else {
              callback(new Error("链接地址无效"));
            }
          });
      };
      return {
        pickerOptions: {
          disabledDate(time) {
            return (
              time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000 ||
              time.getTime() > Date.now() + 3 * 31 * 24 * 60 * 60 * 1000
            );
          },
        },
        currentState: "add",
        uploadLoading: false,
        uploadPath:
          envInfo.bgApp.archivePath +
          "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
        imageUrl: null,
        ruleForm: {
          noticeTitle: null,
          pubdate: null,
          showAddress: null,
          publisher: null,
          noticeType: "1",
          photoIdListId: [],
          linkAddress: null,
        },
        rules: {
          noticeTitle: [
            { required: true, message: "标题公告为必填项", trigger: "blur" },
          ],
          pubdate: [
            { required: true, validator: validatorPubdate, trigger: "blur" },
          ],
          showAddress: [
            { required: true, message: "展示位置为必填", trigger: "blur" },
          ],
          noticeType: [
            { required: true, message: "公告类型为必填", trigger: "blur" },
          ],
          // photoIdListId: [
          //   { required: true, validator: validatorPhoto, trigger: "blur" },
          // ],
          linkAddress: [
            {
              required: true,
              validator: validatorLinkAddress,
              trigger: "blur",
            },
          ],
        },
      };
    },
    methods: {
      getNoticeDetail(id) {
        getNoticeDetailById({ id }).then((res) => {
          console.log("res", new Date(res.data.pubdate).getTime());
          this.ruleForm.noticeTitle = res.data.noticeTitle;
          this.ruleForm.pubdate = new Date(res.data.pubdate).getTime();
          this.ruleForm.showAddress = res.data.showAddress;
          this.ruleForm.publisher = res.data.publisher;
          this.ruleForm.noticeType = res.data.noticeType;
          this.ruleForm.photoIdListId = res.data.photoIdListId;
          this.ruleForm.linkAddress = res.data.linkAddress;
          this.imageUrl = res.data.photoIdListId[0]
            ? envInfo.bgApp.archivePath +
              "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
              res.data.photoIdListId[0]
            : "";
        });
      },
      handleAvatarSuccess(files) {
        this.ruleForm.photoIdListId = [files[0].fileId];
        this.imageUrl =
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          files[0].fileId;
        this.uploadLoading = false;
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === "image/jpeg";
        const isPNG = file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;
  
        if (!isJPG && !isPNG) {
          this.$message.error("封面图片只能是 JPG,PNG 格式!");
        }
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
        }
        const returnFlag = (isJPG || isPNG) && isLt2M;
        this.uploadLoading = returnFlag;
        return returnFlag;
      },
      deleteHandler() {
        this.ruleForm.photoIdListId = [];
        this.imageUrl = null;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitHandler();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      submitHandler() {
        const params = { ...this.ruleForm };
        if (this.currentState === "add") {
          addParkNotice(params).then((res) => {
            if (res.code === 200) {
              this.$message.success("新增公告成功");
              this.$emit("close", true);
            } else {
              this.$message.warning("新增公告失败！");
            }
          });
        } else if (this.currentState === "edit") {
          params.id = this.id;
          updateParkNotice(params).then((res) => {
            if (res.code === 200) {
              this.$message.success("更新公告成功");
              this.$emit("close", true);
            } else {
              this.$message.warning("更新公告失败！");
            }
          });
        }
      },
      resetForm() {
        this.$refs["ruleForm"].resetFields();
        this.$emit("close", false);
      },
      changeStateToEdit() {
        this.currentState = "edit";
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .park-notice-dialog {
    .upload-container {
      display: flex;
      .tips-container {
        margin-left: 15px;
        font-size: 12px;
        & > div {
          height: 20px;
          line-height: 20px;
        }
        .delete-icon {
          position: absolute;
          bottom: 0;
          font-size: 14px;
          color: #ff0000;
          cursor: pointer;
        }
      }
    }
    .avatar-uploader {
      border: 1px dotted #ccc;
      height: 178px;
      width: 178px;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
  </style>